<template>
  <el-dialog
    title="手動出場"
    :visible.sync="syncShow"
    :append-to-body="true"
    destroy-on-close
    width="600px"
    @close="handleClose"
  >
    <BaseElForm v-if="displayData" ref="formRef" :model="formData" :rules="formRules" label-width="150px" label-position="left">
      <BaseElFormItem label="進場者姓名">
        <div v-if="inMemberProfile || displayData.identity === 'guest'">{{ get(displayData, 'name') }}</div>
        <div v-else class="underline cursor-pointer text-primary-100" @click="goToProfile(displayData)">{{ get(displayData, 'name') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="進場時間">
        <div>{{ get(displayData, 'entryAt') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="出場時間" prop="exitAt" class="label-top">
        <el-date-picker
          v-model="formData.exitAt"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          placeholder="選擇日期"
          :default-value="new Date()"
          @change="preCalculate"
        />
      </BaseElFormItem>
      <BaseElFormItem label="進場時長">
        <div>{{ get(displayData, 'entryDuration') }}
          <span v-if="exitEntitlement.overtimeMinutes" class="text-sub text-danger">(超時 {{exitEntitlement.overtimeMinutes}} 分鐘)</span></div>
      </BaseElFormItem>
      <BaseElFormItem label="進場權益">
        <div>{{ get(displayData, 'entryEntitlementType') }}</div>
      </BaseElFormItem>
      <BaseElFormItem v-if="get(selectEntryRecord, 'entryEntitlementType') === 'admin'" label="手動進場備註">
        <div>{{ get(displayData, 'entryNote') }}</div>
      </BaseElFormItem>
      <BaseElFormItem v-else label="需扣除點數卡">
        <div class="flex justify-between">
          <div>{{ totalUsedAmount }} 點 <span v-if="exitEntitlement.pointNotEnoughAmount" class="text-sub text-danger">(不足 {{exitEntitlement.pointNotEnoughAmount}} 點)</span></div>
          <BaseElButton v-if="get(exitEntitlement, 'usedEntitlements.length')" type="text" class="btn edit" @click="showPointCard = !showPointCard">
            本次進場使用權益
          </BaseElButton>
        </div>
      </BaseElFormItem>
      <el-collapse-transition>
        <div v-show="showPointCard">
          <div class="pointCard" v-for="item in exitEntitlement.usedEntitlements" :key="item.entitlementId">
            <p class="title">{{ get(item.entitlementRecord, 'entitlementName') }}</p>
            <p class="content">預計扣除 {{get(item, 'usedAmount')}} {{cardUnit(get(item.entitlementRecord, 'entitlementType'))}} {{ balance(item) }}</p>
            <!-- <div class="flex items-center">
              <SVGIcon
                class="mr-[4px]"
                icon="bulletin-stroke_alert"
                fill="var(--danger)"
                width="14px"
                height="14px"
              />
              <p class="text-danger">僅限 hh:mm~hh:mm 使用，超過的時間無法扣除點數</p>
            </div>
            <p class="text-danger">TODO: 單次進場僅限使用 1 小時，超過的時間無法扣除點數</p> -->
          </div>
        </div>
      </el-collapse-transition>
      <BaseElFormItem label="手動出場備註" prop="exitNote" class="label-top">
        <BaseElInput type="textarea" :rows="3" placeholder="請輸入" v-model="formData.exitNote" />
      </BaseElFormItem>
    </BaseElForm>
    <template #footer>
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" style="margin-left: 12px" @click="onSubmit">手動出場</BaseElButton>
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch } from 'vue'
import { get, map, reduce } from 'lodash'
import { useTable } from '@/use/table'
import { entryFilterStatusConfig, entitlementTypeConfig, entitlementTypeUnitConfig } from '@/config/entryControl'
import { useRouter, useRoute } from 'vue-router/composables'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import dayjs from 'dayjs'
import { ExecuteExitControlRecord, PreExitEntitlement } from '@/api/entryControl'

export default defineComponent({
  name: 'ManualExitDialog',
  components: {},
  props: {
    show: Boolean,
    selectEntryRecord: Object,
  },
  setup (props, { emit }) {
    const { dateFormat, shopId } = useTable()
    const {
      initFormData, formData, formRef, loading, checkForm,
    } = useBaseForm()
    const router = useRouter()
    const route = useRoute()
    const showPointCard = ref(false)
    const exitEntitlement = ref({})
    initFormData({
      exitAt: '',
      exitNote: '',
    })
    const syncShow = computed({
      get () {
        return props.show
      },
      set (data) {
        emit('opend:show', data)
      },
    })
    const formRules = computed(() => {
      return {
        exitAt: [noEmptyRules()],
      }
    })
    const inMemberProfile = computed(() => route.name === 'MemberProfile')
    const displayData = computed(() => {
      const data = props.selectEntryRecord
      const diffMunites = dayjs(formData.exitAt).diff(dayjs(get(data, 'entryAt')), 'minute')
      return {
        ...data,
        name: get(data, 'Member.UserInfo.name') || get(data, 'guestInfo.name'),
        // phone: get(data, 'Member.UserInfo.phone') || get(data, 'guestInfo.phone'),
        // entitlementUsages: map(get(data, 'entitlementUsages'), item => item.name).join('、'),
        // isAdmin: get(data, 'entryOrigin') === 'admin',
        entryNote: get(data, 'entryNote') || '-',
        // branchName: get(data, 'Branch.name') || '-',
        entryAt: get(data, 'entryAt') ? dateFormat(get(data, 'entryAt')) : '-',
        // exitAt: get(data, 'exitAt') ? dateFormat(get(data, 'exitAt')) : '-',
        entryEntitlementType: selectType(get(data, 'entryEntitlementType'), 'label'),
        entryDuration: formData.exitAt ? `${diffMunites} 分鐘` : '-',
      }
    })
    const totalUsedAmount = computed(() => {
      const usedEntitlements = get(exitEntitlement.value, 'usedEntitlements')
      return reduce(usedEntitlements, (acc, item) => acc + item.usedAmount, 0)
    })
    const selectStatus = (status, attr) => {
      return get(entryFilterStatusConfig[status], attr)
    }
    const selectType = (status, attr) => {
      return get(entitlementTypeConfig[status], attr)
    }
    const goToProfile = (row) => {
      router.push(`/members/member-profile/${row.MemberId}/entryRecord`)
    }
    const manualExit = () => {
      emit('manualExit')
    }
    const preCalculate = async () => {
      if (!formData.exitAt) return
      const [res, err] = await PreExitEntitlement({
        shopId: shopId.value,
        branchId: get(props.selectEntryRecord, 'BranchId'),
        entryControlRecordId: get(props.selectEntryRecord, 'id'),
        exitAt: formData.exitAt,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      exitEntitlement.value = res
    }
    const balance = (item) => {
      const availableAmount = get(item.entitlementRecord, 'availableAmount')
      const entitlementType = get(item.entitlementRecord, 'entitlementType')
      return availableAmount ? `( 餘額 ${availableAmount} ${cardUnit(entitlementType)} )` : '(無上限)'
    }
    const onSubmit = async () => {
      // TODO: 送出表單
      const pass = await checkForm(formRef.value)
      if (!pass) return
      const [, err] = await ExecuteExitControlRecord({
        shopId: shopId.value,
        branchId: get(props.selectEntryRecord, 'BranchId'),
        entryControlRecordId: get(props.selectEntryRecord, 'id'),
        exitAt: formData.exitAt,
        exitNote: formData.exitNote || undefined,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('手動出場成功')
      emit('close')
      emit('refresh')
    }
    const cardUnit = (type) => {
      return get(entitlementTypeUnitConfig[type], 'label')
    }
    const handleClose = () => {
      formData.exitAt = ''
      formData.exitNote = ''
      exitEntitlement.value = {}
      emit('close')
    }
    watch(syncShow, async(newVal) => {
      if (newVal) {
        formData.exitAt = new Date()
        await preCalculate()
      }
    })
    return {
      selectStatus,
      get,
      goToProfile,
      inMemberProfile,
      displayData,
      manualExit,
      onSubmit,
      formData,
      formRef,
      loading,
      formRules,
      showPointCard,
      preCalculate,
      exitEntitlement,
      cardUnit,
      handleClose,
      syncShow,
      balance,
      totalUsedAmount,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}

.section-block-title {
@apply text-primary-100 font-medium text-[18px] pb-[12px];
}
::v-deep .el-drawer__header {
@apply mb-0;
}

::v-deep .el-textarea {
@apply w-full;
}
.pointCard {
  @apply p-[20px] rounded-[12px] border-[1px] border-solid border-gray-40 mb-[12px] grid gap-[8px];
  .title {
    @apply font-medium text-gray-100 text-normal;
  }
  .content {
    @apply text-gray-60 text-sub;
  }
}
::v-deep .label-top {
  @apply grid grid-flow-row;
}
::v-deep .label-top .el-form-item__content {
  @apply !ml-0
}
::v-deep .label-top .el-form-item__content .el-input  {
  @apply max-w-full
}
</style>
