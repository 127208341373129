<template>
  <div class="class-ticket-record">
    <PageTitle
      :title="`${branchName}進場紀錄`"
      btn="新增"
      btn2="匯出進場紀錄"
      @btnClick="toCreateEntryRecord"
      @btn2Click=";(exportType = 'use'), (dialog.export = true)"
    />

    <FiltersContainer>
      <MemberSearch
        :model.sync="search.member"
        @change="refresh"
        @clear="refresh(true)"
      />
      <BaseElSelect
        v-model="search.status"
        style="max-width: 400px"
        clearable
        placeholder="出場狀態"
        @change="refresh(true)"
      >
        <BaseElSelectOption
          v-for="item in entryFilterStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
    </FiltersContainer>

    <section>
      <BaseTable
        v-loading="loading.table"
        :data="displayData"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="會員" fixed="left" align="center">
          <template slot-scope="scope">
            <div class="underline cursor-pointer" @click="goToProfile(scope.row)">{{ get(scope.row ,'name') }}</div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="entryEntitlementType" label="進場權益" align="center" />
        <BaseElTableColumn prop="entryAt" label="進場時間" align="center" />
        <BaseElTableColumn prop="exitAt" label="出場時間" align="center" />
        <BaseElTableColumn prop="status" label="出場狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="selectStatus(scope.row.status, 'tagType')">
              {{ selectStatus(scope.row.status, 'label') }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              v-if="scope.row.Member"
              editBtn="詳情"
              hideDelete
              copyBtn="手動出場"
              :hideCopy="scope.row.status === 'exited'"
              @edit="showDetail(scope.row)"
              @copy="manualExit(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>

    <EntryRecordDetail
      :show="showDrawer"
      :selectEntryRecord="selectRow"
      @close="closeDrawer"
      @manualExit="manualExit(selectRow)"
    />
    <ExportOptionsDialog
      v-if="dialog.export"
      :useExportTask="ExportType = 'add' ? useClassTicketRecord : useClassTicketRecordUse"
      @close="dialog.export = false"
      @export="prepareExport"
    />
    <ManualExitDialog
      :show="dialog.manualExit"
      :selectEntryRecord="selectRow"
      @close="dialog.manualExit = false;closeDrawer()"
      @refresh="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed, onActivated, onMounted } from 'vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import EntryRecordDetail from '@/views/EntryControl/components/EntryRecordDetail.vue'
import ManualExitDialog from '@/views/EntryControl/components/ManualExitDialog.vue'
import { FindBranchStore } from '@/api/branchStore'
import { GetEntryControlRecord, GetEntryControlRecordCount, FindEntryControlRecord } from '@/api/entryControl'
import { CreateClassTicketRecordSheetExportTask, CreateClassTicketRecordUseSheetExportTask } from '@/api/exportTask'
import { entryFilterStatusConfig, entitlementTypeConfig } from '@/config/entryControl'
import { get, map } from 'lodash'
import dayjs from '@/lib/dayjs'
import { useShop } from '@/use/shop'
import { usePermissions } from "@/use/permissions"
import { useExportCenter } from '@/use/useExportCenter'
import { useTable } from '@/use/table'
import { useRouter, useRoute } from 'vue-router/composables'
import BaseElSelect from '@/components/BaseElSelect.vue'

export default defineComponent({
  name: 'EntryControlRecordList',
  components: {
    MemberSearch,
    EmptyBlock,
    ExportOptionsDialog,
    EntryRecordDetail,
    ManualExitDialog,
  },
  setup (props) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const router = useRouter()
    const route = useRoute()
    const { messageOptions } = useExportCenter()
    const exportType = ref('add')
    const search = reactive({
      // code: '',
      // name: '',
      status: null,
      member: null,
      // MemberId: null,
    })
    const branchName = ref('')
    const dialog = reactive({
      export: false,
      manualExit: false,
    })
    const showDrawer = ref(false)
    const selectRow = ref(null)
    const deleteDialog = ref(false)
    // TODO: 匯出還沒加上
    const useClassTicketRecordUse = computed(() => checkAction('admin.classTicketRecordUse.createSheetExportTask'))
    const useClassTicketRecord = computed(() => checkAction('admin.classTicketRecord.createSheetExportTask'))
    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        const entryAt = get(item, 'entryAt') ? dateFormat(get(item, 'entryAt')) : '-'
        const exitAt = get(item, 'exitAt') ? dateFormat(get(item, 'exitAt')) : '-'
        const name = get(item, 'Member.UserInfo.name')
        const entryEntitlementType = selectType(get(item, 'entryEntitlementType'), 'label')
        return {
          ...item,
          entryAt,
          exitAt,
          name,
          entryEntitlementType,
        }
      })
    })
    const selectStatus = (status, attr) => {
      return get(entryFilterStatusConfig[status], attr)
    }
    const selectType = (status, attr) => {
      return get(entitlementTypeConfig[status], attr)
    }

    const toCreateEntryRecord = () => {
      router.push({ name: 'CreateEntryRecord', params: { id: route.params.id } })
    }

    const prepareExport = async ({ all, range }) => {
      dialog.export = false
      const payload = {
        shopId: shopId.value,
        MemberId: get(search.member, 'id') || undefined,
        // code: search.code || undefined,
        // ClassTicketId: search.classTicket || undefined,
        createdAtStart: get(range, '[0]') ? dayjs(get(range, '[0]')).toDate() : undefined,
        createdAtEnd: get(range, '[0]') ? dayjs(get(range, '[1]')).toDate() : undefined,
      }

      if (exportType.value === 'use' && useClassTicketRecordUse.value) {
        await createSheetExportTask(CreateClassTicketRecordUseSheetExportTask, payload)
      } else if (exportType.value === 'add' && useClassTicketRecord.value) {
        await createSheetExportTask(CreateClassTicketRecordSheetExportTask, payload)
      }
    }
    const createSheetExportTask = async (apiFunc, payload) => {
      const [, err] = await apiFunc(payload)
      if(err) {
        window.$message.error(err)
        return
      }
      window.$message(messageOptions.value)
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        MemberId: search.member ? search.member.id : undefined,
        BranchId: route.params.id,
        status: search.status || undefined,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetEntryControlRecord, payload),
        fetchDataCount(GetEntryControlRecordCount, payload),
      ])
    }
    const refresh = async (resetPage) => {
      if (resetPage) tableOptions.page = 1
      loading.table = true
      await getTableData()
      if (selectRow.value) await findEntryControlRecord()
      loading.table = false
    }
    const closeDrawer = () => {
      showDrawer.value = false
      selectRow.value = null
    }
    const findEntryControlRecord = async (row) => {
      selectRow.value = row
      const [res, err] = await FindEntryControlRecord({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      selectRow.value = res
    }
    const showDetail = async (row) => {
      selectRow.value = row
      await findEntryControlRecord(row)
      showDrawer.value = true
    }
    const manualExit = async (row) => {
      selectRow.value = row
      await findEntryControlRecord(row)
      dialog.manualExit = true
    }
    const goToProfile = (row) => {
      router.push(`/members/member-profile/${row.MemberId}/entryRecord`)
    }
    const findBranchStore = async (id) => {
      const [res, err] = await FindBranchStore({
        shopId: shopId.value,
        id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      branchName.value = res.name
    }
    onMounted( async() => {
      await refresh()
      const branchId = get(route.params, 'id')
      if (branchId) await findBranchStore(branchId)
    })
    onActivated( async() => {
      await refresh()
    })
    return {
      search,
      prepareExport,
      dialog,
      get,
      exportType,
      useClassTicketRecordUse,
      useClassTicketRecord,
      selectStatus,
      refresh,
      loading,
      tableData,
      tableDataCount,
      tableOptions,
      goToProfile,
      closeDrawer,
      deleteDialog,
      selectRow,
      showDrawer,
      dateFormat,
      displayData,
      entryFilterStatusConfig,
      branchName,
      toCreateEntryRecord,
      findEntryControlRecord,
      showDetail,
      manualExit,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  @apply w-full max-w-[400px];
}
</style>
