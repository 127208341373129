<template>
  <div class="classTicket-info mb-[32px]">
    <BaseElForm v-if="displayData" label-width="150px" label-position="left">
      <BaseElFormItem label="進場者姓名">
        <div v-if="inMemberProfile || displayData.identity === 'guest'">{{ get(displayData, 'name') }}</div>
        <div v-else class="underline cursor-pointer text-primary-100" @click="goToProfile(displayData)">{{ get(displayData, 'name') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="進場者電話">
        <div>{{ get(displayData, 'phone') }}</div>
      </BaseElFormItem>
      <div v-if="get(displayData, 'isAdmin')">
        <BaseElFormItem label="手動進場操作人">
          <div>{{ get(displayData, 'EntryOperator.name') }}</div>
        </BaseElFormItem>
        <BaseElFormItem label="手動進場備註">
          <div>{{ get(displayData, 'entryNote') }}</div>
        </BaseElFormItem>
      </div>
      <BaseElFormItem label="使用權益">
        <div>{{ get(displayData, 'entryEntitlementType') === 'admin' ? '後台新增' : get(displayData, 'entitlementUsages') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="進場門市">
        <div>{{ get(displayData, 'branchName') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="進場時間">
        <div>{{ get(displayData, 'entryAt') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="出場時間">
        <div>{{ get(displayData, 'exitAt') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="出場狀態">
        <div class="flex items-center gap-[8px]">
          <Tag :type="selectStatus(displayData.status, 'tagType')" style="display: inline-flex;">
            {{ selectStatus(displayData.status, 'label') }}
          </Tag>
          <BaseElButton v-if="get(displayData, 'status') === 'entered'" type="text" class="btn edit" @click="manualExit">手動出場</BaseElButton>
        </div>
      </BaseElFormItem>
      <div v-if="get(displayData, 'exitOrigin') === 'admin'">
        <BaseElFormItem label="手動出場操作人">
          <div>{{ get(displayData, 'ExitOperator.name') }}</div>
        </BaseElFormItem>
        <BaseElFormItem label="手動出場備註">
          <div>{{ get(displayData, 'exitNote') }}</div>
        </BaseElFormItem>
      </div>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get, map } from 'lodash'
import { useTable } from '@/use/table'
import { entryFilterStatusConfig } from '@/config/entryControl'
import { useRouter, useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'EntryInfoBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { dateFormat } = useTable()
    const router = useRouter()
    const route = useRoute()
    const inMemberProfile = computed(() => route.name === 'MemberProfile')
    const displayData = computed(() => {
      const data = props.data
      const entitlementUsages = map(get(data, 'entitlementUsages'), item => `「${item.entitlementName}」${item.amount} 張`).join('、')
      return {
        ...data,
        name: get(data, 'Member.UserInfo.name') || get(data, 'guestInfo.name'),
        phone: get(data, 'Member.UserInfo.phone') || get(data, 'guestInfo.phone'),
        entitlementUsages,
        isAdmin: get(data, 'entryOrigin') === 'admin',
        entryNote: get(data, 'entryNote') || '-',
        branchName: get(data, 'Branch.name') || '-',
        entryAt: get(data, 'entryAt') ? dateFormat(get(data, 'entryAt')) : '-',
        exitAt: get(data, 'exitAt') ? dateFormat(get(data, 'exitAt')) : '-',
      }
    })
    const selectStatus = (status, attr) => {
      return get(entryFilterStatusConfig[status], attr)
    }
    const goToProfile = (row) => {
      // TODO: 次卡Tab or 點數卡Tab
      router.push(`/members/member-profile/${row.MemberId}/entryRecord`)
    }
    const manualExit = () => {
      emit('manualExit')
     }
    return {
      selectStatus,
      get,
      goToProfile,
      inMemberProfile,
      displayData,
      manualExit,
    }
  },
})
</script>

<style scoped lang="scss">
.btn {
  @apply underline text-normal font-normal;
}
.edit {
  @apply text-action;
}
</style>
